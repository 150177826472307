<template>
  <!-- eslint-disable -->
  <div>
    <b-row class="kb-search-content-info match-height">
      <b-col cols="12">
        <b-card no-body class="pdf-preview-card">
          <div v-for="document in documents">
            <div v-if="document.language == routeLanguage" v-for="list in document.list">
              <b>{{ list.category.name }}</b>
              <ul class="guides-list">
                <li v-for="guide in list.category.guides">
                  <a :href="guide.url" target="_blank">{{ guide.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div v-if="routeLanguage == 'italian'">
            <b>Guida tecnica di integrazione</b>
            <ul class="guides-list">
              <li>
                <a href="/docs/italian/Integrazione_Form_API_tra_due_piattaform_TrafficManager.pdf" target="_blank">
                  Integrazione Form API tra due piattaform TrafficManager</a>
              </li>
            </ul>
          </div>
          <div v-else>
            <b>Technical Integration Guides</b>
            <ul class="guides-list">
              <li><a href="/docs/tracking_flow.pdf" target="_blank">Offers tracking & postback flow, technical integration</a></li>
              <li><a href="/docs/casino_tracking_PDF.pdf" target="_blank">Postback documentation for TrafficManager casino affiliate tracking platform.</a></li>
            </ul>
            <b>Admin Side Guides</b>
            <ul class="guides-list">
              <li><a href="/docs/A_Important_platform_initial_settings.pdf" target="_blank">Important platform initial settings</a></li>
              <li><a href="/docs/B_The_Affiliate_Managers_feature.pdf" target="_blank">The Affiliate Managers feature</a></li>
              <li><a href="/docs/C_Platform_User_Types.pdf" target="_blank">Platform User Types - (How to create and manage your Users)</a></li>
              <li><a href="/docs/D_The_Marketplace.pdf" target="_blank">The Marketplace - How to create the first offer</a></li>
              <li><a href="/docs/E_Setup_your_platform_S2S_Postback_URL.pdf" target="_blank">Setup your platform S2S Postback URL</a></li>
              <li><a href="/docs/F_Setup_your_platform_Pixel.pdf">Setup your platform Pixel</a></li>
              <li><a href="/docs/G_Enable_affiliate_pixels_in_the_Marketplace_offers.pdf" target="_blank">Enable affiliate pixels in the Marketplace offers</a></li>
              <li><a href="/docs/Postback_URL_integration.pdf" target="_blank">Postback URL integration between TrafficManager and 3rd party platforms</a></li>
              <li><a href="https://www.trafficmanager.com/woocommerce-plugin/" target="_blank">TrafficManager WooCommerce plugin</a></li>
            </ul>
            <b>Publishers - Affiliates Side Guides</b>
            <ul class="guides-list">
              <li><a href="/docs/How_to_setup_a_Postback_URL_from_an_Affiliate_account.pdf" target="_blank">How to setup a Postback URL from an Affiliate account</a></li>
              <li><a href="/docs/MarketPlace_settings_guide.pdf" target="_blank">MarketPlace settings guide</a></li>
              <li><a href="/docs/how_to_add_a_custom_domain.pdf" target="_blank">How to add a custom domain</a></li>
            </ul>
            <b>Other features to explore</b>
            <ul class="guides-list">
              <li><a href="/docs/Others/1_Ticketing_and_Messaging_System.pdf" target="_blank">Ticketing and Messaging System</a></li>
              <li><a href="/docs/Others/2_The_Newsletter_Feature.pdf" target="_blank">The Newsletter Feature</a></li>
              <li><a href="/docs/Others/3_Clicks_Conversions_and_Events_Logs.pdf" target="_blank">Clicks, Conversions and Events Logs</a></li>
              <li><a href="/docs/Others/4_Advertiser_Stats.pdf" target="_blank">Advertiser Stats</a></li>
              <li><a href="/docs/Others/5_The_Admin_Affiliate_Dashboard.pdf" target="_blank">The Admin Affiliate Dashboard</a></li>
              <li><a href="/docs/Others/6_Affiliate_Payments.pdf" target="_blank">Affiliate Payments</a></li>
              <li><a href="/docs/Others/7_Pending_Conversions.pdf" target="_blank">Pending Conversions</a></li>
              <li><a href="/docs/Others/8_Invoicing.pdf" target="_blank">Invoicing</a></li>
              <li><a href="/docs/Others/9_The_referral_program_feature.pdf" target="_blank">The referral program feature</a></li>
            </ul>
          </div> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      routeLanguage: this.getRouteLanguage(this.$route.path),
      documents: [
        {
          language: 'english',
          list: [
            {
              category: {
                name: 'Technical Integration Guides',
                guides: [
                  {
                    name: 'Offers tracking & postback flow, technical integration',
                    url: '/docs/tracking_flow.pdf',
                  },
                  {
                    name: 'Postback documentation for TrafficManager casino affiliate tracking platform',
                    url: '/docs/casino_tracking_PDF.pdf',
                  },
                ],
              },
            },
            {
              category: {
                name: 'Admin Side Guides',
                guides: [
                  {
                    name: 'Important platform initial settings',
                    url: '/docs/A_Important_platform_initial_settings.pdf',
                  },
                  {
                    name: 'The Affiliate Managers feature',
                    url: '/docs/B_The_Affiliate_Managers_feature.pdf',
                  },
                  {
                    name: 'Platform User Types - (How to create and manage your Users)',
                    url: '/docs/C_Platform_User_Types.pdf',
                  },
                  {
                    name: 'The Marketplace - How to create the first offer',
                    url: '/docs/D_The_Marketplace.pdf',
                  },
                  {
                    name: 'Setup your platform S2S Postback URL',
                    url: '/docs/E_Setup_your_platform_S2S_Postback_URL.pdf',
                  },
                  {
                    name: 'Setup your platform Pixel',
                    url: '/docs/F_Setup_your_platform_Pixel.pdf',
                  },
                  {
                    name: 'Enable affiliate pixels in the Marketplace offers',
                    url: '/docs/G_Enable_affiliate_pixels_in_the_Marketplace_offers.pdf',
                  },
                  {
                    name: 'Postback URL integration between TrafficManager and 3rd party platforms',
                    url: '/docs/Postback_URL_integration.pdf',
                  },
                  {
                    name: 'TrafficManager WooCommerce plugin',
                    url: 'https://www.trafficmanager.com/woocommerce-plugin/',
                  },
                  {
                    name: 'How to add Custom Domain in TrafficManager - Admin Side',
                    url: '/docs/how_to_add_a_custom_domain_in_TrafficManager_-_admin_side.pdf',
                  },
                  {
                    name: "How To Import Conversions from CSV or Excel in TrafficManager",
                    url: '/docs/How_To_Import_Conversions_from_CSV_or_Excel_in_TrafficManager.pdf',
                  },
                  {
                    name: "How to Add a Custom Question to the Affiliate Registration Form in TrafficManager",
                    url: '/docs/How_to_Add_a_Custom_Question_to_the_Affiliate_Registration_Form_in_TrafficManager.pdf',
                  }
                ]
              }
            },
            {
              category: {
                name: 'Publishers - Affiliates Side Guides',
                guides: [
                  {
                    name: 'How to setup a Postback URL from an Affiliate account',
                    url: '/docs/How_to_setup_a_Postback_URL_from_an_Affiliate_account.pdf',
                  },
                  {
                    name: 'MarketPlace settings guide',
                    url: '/docs/MarketPlace_settings_guide.pdf',
                  },
                  {
                    name: 'How to add Custom Domain in TrafficManager - Affiliate Side',
                    url: '/docs/how_to_add_a_custom_domain_in_TrafficManager_-_affiliate_side.pdf',
                  }
                ],
              }
            },
            {
              category: {
                name: 'Other features to explore',
                guides: [
                  {
                    name: 'Ticketing and Messaging System',
                    url: '/docs/Others/1_Ticketing_and_Messaging_System.pdf',
                  },
                  {
                    name: 'The Newsletter Feature',
                    url: '/docs/Others/2_The_Newsletter_Feature.pdf',
                  },
                  {
                    name: 'Clicks, Conversions and Events Logs',
                    url: '/docs/Others/3_Clicks_Conversions_and_Events_Logs.pdf',
                  },
                  {
                    name: 'Advertiser Stats',
                    url: '/docs/Others/4_Advertiser_Stats.pdf',
                  },
                  {
                    name: 'The Admin Affiliate Dashboard',
                    url: '/docs/Others/5_The_Admin_Affiliate_Dashboard.pdf',
                  },
                  {
                    name: 'Affiliate Payments',
                    url: '/docs/Others/6_Affiliate_Payments.pdf',
                  },
                  {
                    name: 'Pending Conversions',
                    url: '/docs/Others/7_Pending_Conversions.pdf',
                  },
                  {
                    name: 'Invoicing',
                    url: '/docs/Others/8_Invoicing.pdf',
                  },
                  {
                    name: 'The referral program feature',
                    url: '/docs/Others/9_The_referral_program_feature.pdf',
                  },
                ]
              }
            }
          ]
        },
        {
          language: 'italian',
          list: [
            {
              category: {
                name: 'Guida tecnica di integrazione',
                guides: [
                  {
                    name: 'Integrazione Form API tra due piattaform TrafficManager',
                    url: '/docs/italian/Integrazione_Form_API_tra_due_piattaform_TrafficManager.pdf',
                  },
                ],
              }
            },
          ]
        },
      ]
    }
  },
  watch: {
    $route() {
      this.routeLanguage = this.getRouteLanguage(this.$route.path)
    },
  },
  methods: {
    getRouteLanguage(path) {
      const [, , language] = path.split('/');
      const supportedLanguages = ['english', 'italian', 'serbian'];
      return supportedLanguages.includes(language.toLowerCase()) ? language.toLowerCase() : 'english';
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.pdf-preview-card {
  padding: 60px 100px 50px 80px;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px) {
  .pdf-preview-card {
    padding: 40px 20px 20px 20px !important;
  }

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px) {}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px) {}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px) {}

/* smaller phone */
@media only screen and (max-width: 365px) {}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
